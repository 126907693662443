





















































































import Vue from "vue";
import SearchJob from "@/components/candidate/candidate_jobs/SearchJob.vue";
import JobCard from "@/components/candidate/candidate_jobs/JobCard.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_FAV_JOBS,
  GET_MATCHING_JOBS,
  UPDATE_FAV_JOBS_STATUS,
  MATCHING_JOBS,
  CANDIDATE_JOBS,
  MATCHING_JOBS_TITLE,
  MATCHING_JOBS_LOADING
} from "@/store/modules/candidates/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { generate_random_key } from "@/utils/global";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import {
  GetMatchingJobsPayload,
  MatchingJob,
  MatchingJobs,
  UpdateFavJobsStatus
} from "@/store/modules/candidates/interfaces";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "CandidateJobs",
  components: { DataNotFound, JobCard, SearchJob },
  data() {
    return {
      loading: false,
      form: false,
      search: "",
      cv_id: ""
    };
  },
  async mounted() {
    // If job filter isn't applied
    if (!this.candidate_jobs.matching_jobs.job_title)
      await this.process_matching_jobs();
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    }),
    ...mapGetters("candidate", {
      candidate_jobs: CANDIDATE_JOBS
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  methods: {
    getTitle(): string {
      return this.$t("shared.no-matching-job").toString();
    },
    generate_random_key,
    ...mapActions("candidate", {
      fetch_matching_jobs: GET_MATCHING_JOBS,
      get_fav_jobs: GET_FAV_JOBS
    }),
    ...mapMutations("candidate", {
      update_fav_jobs_status: UPDATE_FAV_JOBS_STATUS,
      set_matching_jobs: MATCHING_JOBS,
      set_matching_jobs_title_filter: MATCHING_JOBS_TITLE,
      set_matching_jobs_loading: MATCHING_JOBS_LOADING
    }),
    /**
     * Function to search job by title
     * and will update searched_matching_jobs data set in store
     * @param title
     */
    async search_job_title(title: string) {
      this.set_matching_jobs({
        loading: true,
        results: [],
        total: 0,
        page: 1,
        limit: 12,
        job_title: title
      });
      await this.process_matching_jobs();
    },
    // /**
    //  * Function to update fav job status
    //  */
    async play_with_fav_jobs() {
      const job_ids = this.candidate_jobs.matching_jobs.results.map(
        (job: MatchingJob) => job.jid
      );
      if (job_ids.length <= 0) return;
      const fav_jobs = await this.get_fav_jobs(job_ids);
      if (fav_jobs.length > 0) {
        const payload: UpdateFavJobsStatus = {
          fav_jobs: fav_jobs
        };
        this.update_fav_jobs_status(payload);
      }
    },
    async reset_jobs() {
      this.set_matching_jobs({
        loading: true,
        results: [],
        total: 0,
        page: 1,
        limit: 12
      });
      await this.process_matching_jobs();
    },
    async process_matching_jobs() {
      this.set_matching_jobs_loading(true);
      const payload: GetMatchingJobsPayload = {
        candidate_id: this.get_user_details.id,
        page: this.candidate_jobs.matching_jobs.page - 1,
        limit: this.candidate_jobs.matching_jobs.limit
      };
      if (this.candidate_jobs.matching_jobs.job_title) {
        payload.job_title = this.candidate_jobs.matching_jobs.job_title;
      }
      const response = await this.fetch_matching_jobs(payload);
      if (response) {
        const payload_matching_jobs: MatchingJobs = {
          loading: false,
          limit: this.candidate_jobs.matching_jobs.limit,
          page: this.candidate_jobs.matching_jobs.page,
          results: response.results,
          total: response.total
        };
        if (this.candidate_jobs.matching_jobs.job_title) {
          payload_matching_jobs.job_title =
            this.candidate_jobs.matching_jobs.job_title;
        }
        this.set_matching_jobs(payload_matching_jobs);
        await this.play_with_fav_jobs();
      }
    }
  }
});
