var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"candidate_jobs"}},[_c('SearchJob',{attrs:{"disabled":_vm.candidate_jobs.matching_jobs.loading},on:{"reset_jobs":_vm.reset_jobs,"search_job_title":_vm.search_job_title}}),_c('div',{staticClass:"my-10 jobs"},[(_vm.candidate_jobs.matching_jobs.loading)?[_c('v-row',_vm._l((15),function(key){return _c('v-col',{key:key,attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('div',{staticClass:"base-card"},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)])}),1)]:(
        !_vm.candidate_jobs.matching_jobs.loading &&
        _vm.candidate_jobs.matching_jobs.results.length <= 0
      )?[_c('v-row',{staticClass:"mt-15 pt-15",attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('DataNotFound',{attrs:{"title":_vm.getTitle()}})],1)],1)]:[_c('v-row',{staticClass:"jobs-wrapper"},_vm._l((_vm.candidate_jobs.matching_jobs.results),function(job){return _c('JobCard',{key:job.jid * _vm.generate_random_key(),attrs:{"module":"candidate","job":job}})}),1),_c('div',{staticClass:"t-footer"},[_c('v-row',{staticClass:"mt-16",attrs:{"align":"center","justify":"center"}},[(_vm.candidate_jobs.matching_jobs.loading)?[_c('v-skeleton-loader',{staticClass:"d-flex align-center mx-4 pagination-loader",attrs:{"tile":true,"type":"avatar@4"}})]:(
              _vm.candidate_jobs.matching_jobs.results.length &&
              _vm.candidate_jobs.matching_jobs.total > 12
            )?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"pagination-wrapper",attrs:{"color":"primary","length":Math.ceil(_vm.candidate_jobs.matching_jobs.total / 12),"total-visible":6,"prev-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                    ? 'mdi-arrow-left'
                    : 'mdi-arrow-right',"next-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                    ? 'mdi-arrow-right'
                    : 'mdi-arrow-left'},on:{"input":_vm.process_matching_jobs},model:{value:(_vm.candidate_jobs.matching_jobs.page),callback:function ($$v) {_vm.$set(_vm.candidate_jobs.matching_jobs, "page", $$v)},expression:"candidate_jobs.matching_jobs.page"}})],1)]:_vm._e()],2)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }